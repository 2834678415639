import {CURRENCY, FieldType, GroupField, LookupType} from "@/core/config/Constant";

export const AetnaFieldConfig = [
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Currency',
        type: FieldType.SELECT,
        key: 'currency',
        subKey: '',
        id: 'currency',
        subName: '',
        data: CURRENCY,
      },
      {
        col: 'col-md-6',
        name: 'Date invoice received',
        type: FieldType.DATETIME,
        key: 'receivedDate',
        subKey: '',
        id: 'currency',
        subName: '',
      },
    ]
  },
  {
    heading: 'Claim information',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-3',
        name: 'Claim ID',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'aetnaId',
        id: 'aetnaId',
        subName: '',
      },
      {
        col: 'col-md-3',
        name: 'Claim Type',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'aetnaType',
        id: 'aetnaType',
        subName: '',
      },
      {
        col: 'col-md-3',
        name: 'Suffix',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'suffix',
        id: 'suffix',
        subName: '',
      },
      {
        col: 'col-md-3',
        name: 'Segment Count',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'segmentCount',
        id: 'segmentCount',
        subName: '',
      },
    ]
  },
  {
    heading: 'Patient information',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-3',
        name: 'Member ID',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'memberId',
        id: 'memberId',
        subName: '',
      },
      {
        col: 'col-md-3',
        name: 'Patient',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'patient',
        id: 'patient',
        subName: '',
      },
      {
        col: 'col-md-3',
        name: 'Principal diagnosis code',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'diag1',
        id: 'diag1',
        checkCode: true,
        lookupType: LookupType.ICD10CM
      },

    ]
  },
  {
    heading: 'Claim Data',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-3',
        name: 'Anesthesia Minutes',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'anesthesiaMinute',
        id: 'anesthesiaMinute',
        subName: '',
      },
      {
        col: 'col-md-3',
        name: 'Anesthesia Units ',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'anesthesiaUnit',
        id: 'anesthesiaUnit',
        subName: '',
      },
      {
        col: 'col-md-3',
        name: 'COB IND',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'cobInd',
        id: 'cobInd',
        subName: '',
      },
      {
        col: 'col-md-3',
        name: 'Medicaid Paid Amount',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'medicaidPaidAmt',
        id: 'medicaidPaidAmt',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.LINES
  },

  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Federal Tax',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'federalTaxNum',
        id: 'federalTaxNum',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Patient account number',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'patientAccNumber',
        id: 'patientAccNumber',
        subName: '',
      },
    ]
  },
  {
    heading: 'Service Provider',
    type: GroupField.PROVIDER,
    key: 'serviceProvider',
    service: false,
  },
  {
    heading: 'Billing Provider',
    type: GroupField.PROVIDER,
    key: 'billingProvider',
    subKey: '',
    service: false,
  },
]