
import {computed, defineComponent, ref} from 'vue'
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import AetnaClaimFormUpload from "@/views/invoice/aetna-claim/AetnaClaimFormUpload.vue";
import {useToast} from "vue-toast-notification";
import store from "@/store";
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import AetnaClaimStatus from "@/views/invoice/aetna-claim/AetnaClaimStatus.vue";
import AetnaCreateInvoiceForm from "@/views/invoice/aetna-claim/AetnaCreateInvoiceForm.vue";

export default defineComponent({
  name: "List",
  props: {
    status: {required: true, type: String, default: 'NEW'},
  },
  components: {
    AetnaCreateInvoiceForm,
    AetnaClaimStatus,
    DateTimeFormat, KtDatatable, AetnaClaimFormUpload, BaseModal, QuickAction, Entities,
  },
  setup(props) {
    const page = computed(() => store.state.AetnaClaimModule.page);
    const model = ref({})
    const objectFilters = ref({aetnaStatus: props.status, type: 'AETNA', aetnaId: ''})
    const header = [
      {
        name: "Aetna ID",
        key: "claimId",
        sortable: true,
      },
      {
        name: "Member ID",
        key: "memberId",
        sortable: true,
      },
      {
        name: "Claim#",
        key: "claim",
        sortable: true,
      },
      {
        name: "Segments",
        key: "segmentCount",
        sortable: true,
      },
      {
        name: "Patient",
        key: "patientId",
        sortable: true,
      },
      {
        name: "Service Date",
        key: "serviceDt",
        sortable: true,
      },
      {
        name: "Submitted",
        key: "submitted",
        sortable: true,
      },
      {
        name: "Allowed",
        key: "allowed",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },

      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]
    return {
      model,
      objectFilters,
      header,
      page,
      ...LoadFilterObjects(Actions.LOAD_AETNA_CLAIMS, objectFilters.value, ['claim']),
      ...LoadPanel(),
    }
  },
  watch: {
    status(cb) {
      this.objectFilters.aetnaStatus = cb;
      this.updateFilterObject(this.objectFilters)
    },
  },
  methods: {
    onImport() {
      const modal = this.$refs.formAetnaClaimRef as typeof BaseModal
      modal.showBaseModal();
    },
    onImported() {
      const modal = this.$refs.formAetnaClaimRef as typeof BaseModal
      modal.hideBaseModal();
      useToast().success('Import in processing, please refresh in a few seconds', {position: 'top-right'})
      this.paginationLoad()
    },
    onSearch() {
      this.updateFilterObject(this.objectFilters)
    },
    onDiscard() {
      this.objectFilters = {aetnaStatus: this.status, type: 'AETNA', aetnaId: ''};
      this.paginationLoad()
    },
    onComplete(inv) {
      this.model = inv;
      const modal = this.$refs.aetnaClaimCreateInvoiceRef as typeof BaseModal
      modal.showBaseModal();
    }
  }
})
