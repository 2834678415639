import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-lg-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseForm = _resolveComponent("BaseForm")!

  return (_openBlock(), _createBlock(_component_BaseForm, {
    "submit-button-name": "Import",
    onFormSubmit: _ctx.onSubmitSend,
    submitting: _ctx.submitting,
    validate: {}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("input", {
          type: "file",
          id: "file-type",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSelectedFile && _ctx.onSelectedFile(...args))),
          accept: '*'
        }, null, 32)
      ])
    ]),
    _: 1
  }, 8, ["onFormSubmit", "submitting"]))
}