
import {defineComponent, onMounted, ref} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import {useToast} from "vue-toast-notification";
import AetnaClaimService from "@/core/services/AetnaClaimService";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "AetnaClaimFormUpload",
  components: {BaseForm},
  emits: ['saved'],
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Aetna Claims',
        [
          {link: false, router: '', text: 'Aetna Claims'}
        ]
      )
    })
    const file = ref();
    const submitting = ref(false);
    return {
      file, submitting
    }
  },
  methods: {
    onSubmitSend() {
      if (!this.file) {
        useToast().error("Please select file", {position: 'top-right'})
      }
      this.submitting = true;
      const data = {file: this.file}
      AetnaClaimService.upload(data).then(res => {
        this.$emit('saved', res)
      }).finally(() => {
        this.submitting = false
      })
    },
    onSelectedFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.file = e.target.files[0]
    }
  }
})
