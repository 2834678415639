
import {computed, defineComponent, ref, watch} from 'vue'
import {INVOICE_TYPES} from "@/core/config/Constant";
import BaseInvoiceForm from "@/views/invoice/BaseInvoiceForm.vue";
import AetnaCreateInvoiceUploadForm from "@/views/invoice/aetna-claim/AetnaCreateInvoiceUploadForm.vue";
import {AetnaFieldConfig} from "@/core/entity/AetnaFieldConfig";
import {ModelAddress} from "@/core/entity/IAddress";
import InvoiceService from "@/core/services/InvoiceService";
import {ILocation} from "@/core/entity/ILocation";
import store from "@/store";

export default defineComponent({
  name: "AetnaCreateInvoiceForm",
  components: {AetnaCreateInvoiceUploadForm, BaseInvoiceForm},
  props: {
    invoice: {type:Object, required: true},
  },
  setup(props) {
    const states = computed<ILocation[]>(() => store.state.LocationModule.stateAll)
    const docId = ref('')
    const model = ref<any>();
    const load = (id) => {
      if (id) {
        InvoiceService.get(id).then(res => {
          model.value = res;
        })
      }
    }
    load(props.invoice.id)
    watch(() => props.invoice.id, cb => {
      load(cb)
    })
    return {
      INVOICE_TYPES,
      AetnaFieldConfig,
      ModelAddress,
      docId,
      model,
      states,
    }
  },
  methods: {
    uploaded(doc) {
      this.docId = doc
    },
    onSaved(invoice) {
      this.$router.push({name: 'invoice', params: {id: invoice.id}})
    },
    onExtractedFile(json: any) {
      if (json) {
        this.model.data.diag1 = json?.patientInformation?.principalDiagnosisCode;
        this.model.data.anesthesiaMinute = json?.claimData?.anesthesiaMinutes;
        this.model.data.anesthesiaUnit = json?.claimData?.anesthesiaUnits;
        this.model.data.medicaidPaidAmt = json?.claimData?.medicaidPaidAmount;
        this.model.data.federalTaxNum = json?.federalTax
        this.model.data.patientAccNumber = json?.patientAccountNumber
        this.model.lines = json.billingDetails.map(item => ({
          cobAdj: 0,
          cobPaid: 0,
          medicaidPaid: 0,
          ndc: 0,
          ndcQl: 0,
          amount: item.submittedCharge,
          cpt: item.cpt,
          desc: '',
          diagnosis: item.diagnosisPointer,
          emg: '',
          fromDt: item.dosFrom ? new Date(item.dosFrom) : this.model.dos,
          modifier: item?.modifiers[0],
          pos: item.placeOfService,
          repriced: 0,
          rev: '',
          toDt: item.dosTo ? new Date(item.dosTo) : this.model.dos2,
          type: 'AETNA',
          units: 0
        }));
      }
      if (json.serviceProvider) {
        const state = this.states.find(item => item.code === json.serviceProvider.state)
        const address = new ModelAddress()
        address.stateCode = json.serviceProvider.state
        if (state) {
          address.stateName = state.name
          address.countryCode = state.parent?.code
          address.countryName = state.parent?.name
        }

        address.street = json.serviceProvider.address
        address.zip = json.serviceProvider.zip
        address.cityName = json.serviceProvider.city
        this.model.serviceProvider = {
          selfId: '',
          name: json.serviceProvider.name,
          npi: '',
          address: address
        }
      }
      if (json.billingProvider) {
        const state = this.states.find(item => item.code === json.billingProvider.state)
        const address = new ModelAddress()
        address.stateCode = json.billingProvider.state
        if (state) {
          address.stateName = state.name
          address.countryCode = state.parent?.code
          address.countryName = state.parent?.name
        }
        address.street = json.billingProvider.address
        address.zip = json.billingProvider.zip
        address.cityName = json.billingProvider.city
        this.model.billingProvider = {
          selfId: '',
          name: json.billingProvider.name,
          npi: json.billingProvider.npi,
          address: address
        }
      }
    }
  }
})
