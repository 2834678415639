
import {computed, defineComponent, ref, watch} from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {ModelAddress} from "@/core/entity/IAddress";
import Row from "@/components/base/common/Row.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Field} from "vee-validate";
import {HandleState} from "@/core/composite/composite";
import {ILocation} from "@/core/entity/ILocation";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import ProviderService from "@/core/services/ProviderService";
import * as Yup from "yup";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import LocationService from "@/core/services/LocationService";

export default defineComponent({
  name: "InvoiceProviderSearch",
  components: {FormErrorMessage, BaseSelect, BaseForm, BaseModal, Row, Field, QuickAction},
  props: {
    label: {type: String, default: 'Provider'},
    modelValue: {required: true},
    id: {},
    service: {type: Boolean, required: false, default: () => false}
  },
  emits: ['update:modelValue', 'same'],
  setup(props) {
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)

    const model = ref({name: '', address: new ModelAddress(), npi: '', tin: ''});
    const updateModel = ref({name: '', address: new ModelAddress(), npi: '', tin: ''})
    const provider = ref({name: '', npi: '', fullAddress: ''});
    const terms = ref('');
    const search = () => {
      store.dispatch(Actions.PROVIDER_LIVE_SEARCH, terms.value);
    }
    const submitting = ref(false);
    const selectProvider = async (provider, index) => {
      const address = new ModelAddress();
      address.zip = providerSearch.value[2]['addr_practice.zip'][index]
      address.street = providerSearch.value[2]['addr_practice.line1'][index]
      address.street2 = providerSearch.value[2]['addr_practice.line2'][index]
      address.stateCode = providerSearch.value[2]['addr_practice.state'][index]
      address.countryCode = providerSearch.value[2]['addr_practice.country'][index]
      address.cityName = providerSearch.value[2]['addr_practice.city'][index]
      const countryName = countries.value.find((item) => item.code === address.countryCode)?.name
      if (countryName) address.countryName = countryName
      await LocationService.listByCode(address.countryCode).then(res => {
        const name = res.find((item) => item.code === address.stateCode)?.name
        if (name) address.stateName = name
      })
      model.value = {name: provider[0], npi: provider[1], address: address, tin: ''};
      updateModel.value = {name: provider[0], npi: provider[1], address: address, tin: ''}
      store.commit(Mutations.SET_PROVIDER_LIVE_SEARCH, {})
    }
    const load = (id) => {
      if (id !== undefined && id != '') {
        ProviderService.get<any>(id).then(res => {
          console.log(res);
          provider.value.npi = res.npi;
          provider.value.name = res.name;
          provider.value.fullAddress = res.address ? res.address.fullAddress : ''
          updateModel.value.name = res.name
          updateModel.value.address = res.address ? res.address : new ModelAddress();
          updateModel.value.npi = res.npi
          updateModel.value.tin = res.tin
        })
      }
    }
    load(props.modelValue);
    const searching = computed(() => store.state.ProviderModule.searching);
    const providerSearch = computed(() => store.state.ProviderModule.providerSearch);
    const validate = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      npi: Yup.string().required().label("Npi"),
    });
    watch(() => props.modelValue, cb => {
      load(cb);
    })
    return {
      ...HandleState(),
      terms,
      searching,
      providerSearch,
      search,
      selectProvider,
      model,
      countries,
      states,
      submitting,
      provider,
      updateModel,
      validate,
    }
  },
  methods: {
    onSave() {
      this.submitting = true;
      ProviderService.create<any>(this.model).then(res => {
        this.provider.name = res.name
        this.provider.npi = res.npi
        this.provider.fullAddress = res.address ? res.address.fullAddress : ''
        this.$emit('update:modelValue', res.id)
        const modal = this.$refs['invoiceSearchProviderRef_' + this.id] as typeof BaseModal
        modal.hideBaseModal()
      }).finally(() => {
        this.submitting = false;
      })
    },

    onUpdateModal() {
      const baseModal = this.$refs['invoiceEditProviderRef_' + this.id] as typeof BaseModal
      baseModal.showBaseModal()
    },

    async onSaveUpdate() {
      this.submitting = true;
      if (this.updateModel.address.stateName == "" || this.updateModel.address.stateName == null) {
        await LocationService.listByCode(this.updateModel.address.countryCode).then(res => {
          const name = res.find((item) => item.code === this.updateModel.address.stateCode)?.name
          if (name) this.updateModel.address.stateName = name
        })
      }
      ProviderService.update<any>(this.modelValue, this.updateModel).then(res => {
        this.provider.name = res.name
        this.provider.npi = res.npi
        this.provider.fullAddress = res.address ? res.address.fullAddress : ''
        const baseModal = this.$refs['invoiceEditProviderRef_' + this.id] as typeof BaseModal
        baseModal.hideBaseModal()
      }).finally(() => {
        this.submitting = false
      })
    },

    onStateNameSelect(stateName) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name) {
        this.updateModel.address.stateName = name
        this.model.address.stateName = name;
      }
    },

    onCountryNameSelect(countryName) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name) {
        this.updateModel.address.countryName = name;
        this.model.address.countryName = name;
      }
    },

    sameAsService() {
      this.$emit('same')
    },
    onSearch() {
      const modal = this.$refs['invoiceSearchProviderRef_' + this.id] as typeof BaseModal
      modal.showBaseModal();
    }
  }
})
