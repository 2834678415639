
import {defineComponent, ref} from 'vue'
import Swal from "sweetalert2";
import AetnaClaimService from "@/core/services/AetnaClaimService";

export default defineComponent({
  name: "AetnaCreateInvoiceUploadForm",
  props: {},
  setup() {
    const doc = ref({});
    const file = ref('')
    const submitting = ref(false);
    return {
      submitting,
      file,
      doc,
    }
  },
  emits: ['uploaded', 'jsonExtracted'],
  methods: {
    handleFileSelect(event) {
      this.file = event.target.files[0];
    },
    handleDrop(event) {
      this.file = event.dataTransfer.files[0];
    },
    triggerFileSelect() {
      const input = this?.$refs?.fileInput;
      if (input instanceof HTMLInputElement) {
        input?.click();
      }
    },
    uploadFile() {
      if (!this.file) return;
      const formData = new FormData();
      formData.append('file', this.file);
      this.submitting = true;
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      AetnaClaimService.uploadDocument(formData).then(res => {
        this.doc = res;
        this.$emit("uploaded", res.id);
        if (res.extractJson) {
          this.$emit("jsonExtracted", JSON.parse(res.extractJson));
        }
      }).finally(() => {
        this.submitting = false;
        Swal.close();
      })
    }
  }
})
