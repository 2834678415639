
import {computed, defineComponent} from 'vue'
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {ILocation} from "@/core/entity/ILocation";
import store from "@/store";
import {HandleState} from "@/core/composite/composite";

export default defineComponent({
  name: "InvoiceProviderEditForm",
  components: {BaseSelect, Field, FormErrorMessage},
  props: {
    modelValue: {required: true},
    label: {},
    id: {},
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)

    const states = computed<ILocation[]>(() => store.state.LocationModule.stateAll)

    const model = computed<any>({
      get() {
        return props.modelValue
      },
      set(model) {
        emit('update:modelValue', model)
      }
    })
    return {
      model,
      countries,
      states,
      ...HandleState(),
    }
  },
  methods: {
    getListStates(countryCode) {
      if (!countryCode) return this.states;
      return this.states.filter(item => item.parent.code === countryCode)
    },
    onStateNameSelect(stateName) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name) {
        this.model.address.stateName = name;
      }
    },

    onCountryNameSelect(countryName) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name) {
        this.model.address.countryName = name;
      }
    },
  }

})
