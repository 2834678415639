import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1f2ed66"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "preview mt-3"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "file-upload card" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.doc?.id )
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("iframe", {
            src: _ctx.doc.presignedUrl +'#toolbar=0&view=fitH',
            height: "900px",
            width: "100%",
            class: "border rounded"
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "dropzone border-dashed border-2 border-gray-300 rounded p-5 text-center",
        onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
        onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent"]))
      }, [
        (!_ctx.file)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Drag & drop a file here or click to select"))
          : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.file.name), 1)),
        _createElementVNode("input", {
          type: "file",
          class: "d-none",
          ref: "fileInput",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileSelect && _ctx.handleFileSelect(...args))),
          accept: "application/pdf"
        }, null, 544),
        _createElementVNode("button", {
          class: "btn btn-primary mt-3",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.triggerFileSelect && _ctx.triggerFileSelect(...args)))
        }, "Select File")
      ], 32),
      _createElementVNode("button", {
        class: "btn btn-primary mt-3",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args))),
        disabled: !_ctx.file || _ctx.submitting 
      }, "Upload", 8, _hoisted_6)
    ])
  ], 64))
}