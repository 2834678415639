
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "AetnaClaimStatus",
  props:{
    status :{}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const build = (status) => {
      switch (status) {
        case 'NEW':
          data.value = {name: 'New', clazz: 'secondary'}
          break
        case 'PENDING':
          data.value = {name: 'Pending', clazz: 'primary'}
          break
        case 'PAID':
          data.value = {name: 'Paid', clazz: 'success'}
          break
        case 'DENIED':
          data.value = {name: 'Denied', clazz: 'dander'}
          break
        case 'COMPLETE':
          data.value = {name: 'Complete', clazz: 'success'}
          break
        default:
          data.value =  {name: '', clazz: ''}
      }
    }
    build(props.status)
    watch(() => props.status, (cb) => {
      build(cb)
    })
    return{
      data,
    }
  }
})
